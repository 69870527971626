<template>
    <vs-tabs :position="isSmallerScreen ? 'top' : 'left'" class="tabs-shadow-none" id="profile-tabs" :key="isSmallerScreen">
  
      <!-- GENERAL -->
      <vs-tab icon-pack="feather" icon="icon-user" :label="!isSmallerScreen ? 'ตั้งค่ารูปแบบการโชว์ธนาคาร' : ''">
        <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
          <SettingBankList />
        </div>
      </vs-tab>
      <vs-tab v-if="$store.state.AppActiveUser.permissions.setting.action" icon-pack="feather" icon="icon-lock" :label="!isSmallerScreen ? 'เพิ่มรูปแบบการโชว์ธนาคาร' : ''">
        <div class="tab-change-pwd md:ml-4 md:mt-0 mt-4 ml-0">
          <insertbank />
        </div>
      </vs-tab>
      <vs-tab icon-pack="feather" icon="icon-clock" :label="!isSmallerScreen ? 'สถานะการแสดงธนาคาร' : ''">
        <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
          <StatusBankShow />
        </div>
      </vs-tab>
    </vs-tabs>
  
  </template>
  
<script>
import SettingBankList from './SettingBankList.vue'
import Insertbank from './InsertCustomBank.vue'
import StatusBankShow from './StatusBankShow.vue'
export default {
  components: {
    SettingBankList,
    Insertbank,
    StatusBankShow
  },
  data () {
    return {
    }
  },
  computed: {
    isSmallerScreen () {
      return this.$store.state.windowWidth < 768
    }
  }
}
</script>
  
  <style lang="scss">
  #profile-tabs {
    .vs-tabs--content {
      padding: 0;
    }
  }
  </style>
  