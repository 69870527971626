<template>
  <div>
    <div class="mb-4" v-for="(key, value) in bankshow" :key="value">
    <vx-card  :title="value">

      <vs-table :data="key">

        <template slot="thead">
          <vs-th>ID</vs-th>
          <vs-th>Bank Type</vs-th>
          <vs-th>status</vs-th>
          <vs-th>Bank Show</vs-th>
        </template>


        <template  slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="indextr">
              {{indextr += 1}}
            </vs-td>
            <vs-td :data="tr.name">
              {{tr.name}}
            </vs-td>
            <vs-td :data="tr.status" v-if="tr.status === true" class="text-success">
              เปิด
            </vs-td>
            <vs-td :data="tr.status" v-else class="text-danger">
              ปิด
            </vs-td>
            <vs-td :data="tr.show" v-if="tr.show === true">
              <vs-button  type="filled" color="danger" icon-pack="feather" class="mr-2 size-button">
                ธนาคารที่ลูกค้าเห็นอยู่
              </vs-button>
            </vs-td>
            <vs-td :data="tr.show" v-else/>
          </vs-tr>
        </template>
        
      </vs-table>
    </vx-card>

    </div>

  </div>
</template>

<script>
import axios from '../../axios'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {
  components: {
    vueDropzone: vue2Dropzone
  },
  data () {
    return {
      bankshow: []
    }
  },
  async mounted () {
    await axios
      .get('/bankshow')
      .then(response => (this.bankshow = response.data))
  }
}

</script>

<!-- SASS styling -->
<style lang="scss">
.dropbox {
  outline: 2px dashed grey; /* the dash box */
  outline-offset: -10px;
  background: lightgray;
  color: dimgray;
  padding: 10px 10px;
  min-height: 100px; /* minimum height */
  position: relative;
  cursor: pointer;
}

.size-button {
  padding: 6px 12px;
  font-size: 12px;
}

.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 100px;
  position: absolute;
  cursor: pointer;
}

.dropbox:hover {
  background: aliceblue; /* when mouse over to the drop zone, change color */
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 50px 0;
}
</style>
